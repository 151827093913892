import React from "react";

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ChooseDistance from './ChooseDistance.js';
import CoverLeftEye from './CoverLeftEye.js';
import CoverRightEye from './CoverRightEye.js';
import GridLeftEye from './GridLeftEye.js';
import GridRightEye from './GridRightEye.js';
import EndTest from './EndTest.js';


const Amsler = () => {
  const { path } = useRouteMatch();
  console.log(path);
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={[`${path}`, `${path}/choose-distance`]}>
          <ChooseDistance root_path={path} />
        </Route>
        <Route path={`${path}/cover-left-eye`}>
          <CoverLeftEye root_path={path} />
        </Route>
        <Route path={`${path}/grid-left-eye`}>
          <GridLeftEye root_path={path} />
        </Route>
        <Route path={`${path}/cover-right-eye`}>
          <CoverRightEye root_path={path} />
        </Route>
        <Route path={`${path}/grid-right-eye`}>
          <GridRightEye root_path={path} />
        </Route>
        <Route path={`${path}/end-test`}>
          <EndTest root_path={path} />
        </Route>
      </Switch>
    </React.Fragment>
  );
}


export default Amsler;
