import React, { useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import {Stack, Grid, Button} from '@mui/material';
import Letter from "./Letter";

const getRandomInt = (max) => Math.floor(Math.random() * max);

const showLetter = (level, direction, onClickDirection, userSettings) => <>
  <Letter level={level.letterSize} direction={direction} userSettings={userSettings} />
  <Grid item xs={12} flex="0 1 50px">
    <Stack direction="row" spacing={3} justifyContent="center" alignItems="center" sx={{width:"100%"}}>
      <Button variant="contained" onClick={() => onClickDirection(0)}><ArrowLeftIcon /></Button>
      <Button variant="contained" onClick={() => onClickDirection(1)}><ArrowUpwardIcon /></Button>
      <Button variant="contained" onClick={() => onClickDirection(2)}><ArrowDownwardIcon /></Button>
      <Button variant="contained" onClick={() => onClickDirection(3)}><ArrowRightIcon /></Button>
    </Stack>
  </Grid>
</>

const getUniqueDirection = (previousDirection) =>{
  let uniqDirection;
  do {
    uniqDirection = getRandomInt(3);
  } while (uniqDirection === previousDirection)
  return uniqDirection;
}


const Level = ({levelIndex, level, onLevelFinish, onAnswer, userSettings}) => {
  const [direction, setDirection] = useState(getUniqueDirection(-1));
  const [attempts, setAttempts] = useState(0);

  console.log("Level Render - levelIndex", levelIndex, "direction", direction);

  let previousDirection = direction;

  const onClickDirection = (choosenDirection: number) => {
    onAnswer(levelIndex, choosenDirection === direction);
    setAttempts(attempts + 1);

    if (attempts < level.numberOfTries - 1) {
      setDirection(getUniqueDirection(previousDirection));
      previousDirection = direction;
    } else {
      setAttempts(0)
      onLevelFinish(levelIndex, level);
    }

  }


  return (
    showLetter(level, direction, onClickDirection, userSettings)
  );
}

export default Level;
