import React from "react";
import Container from '@mui/material/Container';
import MainNavigation from 'Layout/MainNavigation.js'
import Layout from 'Layout/Layout.tsx'

const Settings = (props:any) => {
  return (
    <Layout selectedTab={props.selectedTab} onSelectTab={props.onSelectTab} >
      <Container>
        <h2>Settings</h2>
      </Container>
      <MainNavigation selectedTab={props.selectedTab} onSelectTab={props.onSelectTab}/>
    </Layout>
  );
}

export default Settings;
