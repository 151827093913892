import React, { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dashboard from './Pages/Dashboard/Dashboard.js'
import Tests from './Pages/Tests/Tests.js'
import Amsler from './Pages/Tests/Amsler/Amsler.js';
import Acuity from './Pages/Tests/Acuity/Acuity.tsx';
import Appointments from './Pages/Appointments/Appointments.js';
import Settings from './Pages/Settings/Settings.js'

// https://bareynol.github.io/mui-theme-creator/
// https://duncanleung.com/accessing-material-ui-theme-object-emotion-styled-component-css-prop/
// https://mui-treasury.com/styles/switch/
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3d5377',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#1a84ed',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    background: {
      default: '#192231',
      paper: '#ffffff',
    },
    mode: 'light',
    // mode: 'dark',
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const App = () => {

    const [selectedTab, onSelectTab] = useState(0);
    const [screenOrientation, handleScreenRotate] = useState("portrait-primary");
    // screenOrientation: window.screen.orientation.type, // "landscape-primary"

    useEffect(() => {
      window.addEventListener('orientationchange', handleScreenRotate);
      return () => {
        window.removeEventListener('orientationchange', handleScreenRotate);
      }
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/">
                <Dashboard selectedTab={selectedTab} onSelectTab={onSelectTab} />
              </Route>
              <Route exact path="/tests">
                <Tests selectedTab={selectedTab} onSelectTab={onSelectTab} />
              </Route>
              <Route path="/appointments">
                <Appointments selectedTab={selectedTab} onSelectTab={onSelectTab} />
              </Route>
              <Route path="/settings">
                <Settings selectedTab={selectedTab} onSelectTab={onSelectTab} />
              </Route>
              <Route path="/tests-amsler">
                <Amsler selectedTab={selectedTab} onSelectTab={onSelectTab} />
              </Route>
              <Route path="/tests-acuity">
                <Acuity selectedTab={selectedTab} onSelectTab={onSelectTab} screenOrientation={screenOrientation}/>
              </Route>
            </Switch>
          </Router>

        </div>
      </ThemeProvider>
    );
}

export default App;
