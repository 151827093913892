import React from "react";
import MainNavigation from 'Layout/MainNavigation.js'
import Layout from 'Layout/Layout.tsx'

import { Link as RouterLink } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Tests = (props:any) => {
  return (
    <Layout selectedTab={props.selectedTab} onSelectTab={props.onSelectTab} >
      <Container>
          <h2>Take a Test</h2>

          <Box sx={{ height: '100%', width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>

            <nav aria-label="main folders">
              <List>
                <ListItem component={RouterLink} to="/tests-amsler">
                  <ListItemButton>
                    <ListItemIcon>
                      <Grid4x4Icon />
                    </ListItemIcon>
                    <ListItemText
                      primary=<Typography variant="h6" color='secondary'>
                        Amsler
                      </Typography>
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem component={RouterLink} to="/tests-acuity">
                  <ListItemButton>
                    <ListItemIcon>
                      <RemoveRedEyeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Acuity" primaryTypographyProps={{color: 'secondary', variant: "h6"}}/>
                  </ListItemButton>
                </ListItem>

              </List>
            </nav>
          </Box>


      </Container>
      <MainNavigation selectedTab={props.selectedTab} onSelectTab={props.onSelectTab}/>
    </Layout>
  );
}

export default Tests;
