
import React from "react";

import { Link } from "react-router-dom";

import DashboardIcon from '@mui/icons-material/Dashboard';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import { Container } from '@mui/material';

import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";

// https://stackoverflow.com/questions/54375096/styling-bottomnavigation-in-react-js-material-ui/54375949?noredirect=1#comment122864123_54375949
const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color:'success.main';
  &.Mui-selected {
    color: main;
  }
`);


const Finish = (props) => {
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h6" component="div" gutterBottom>
          Amsler Test completed
        </Typography>
        <Typography variant="body2" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
          neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
          quasi quidem quibusdam.
        </Typography>

        <Button variant="contained"
            component={Link}
            to={`/tests`}>Finish</Button>

      </Container>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4}>
        <BottomNavigation
          showLabels
          sx={{
            color: 'success.main',
          }}
        >
          <BottomNavigationAction
            label="Dasbboard"
            icon={<DashboardIcon />}
            component={Link}
            to="/tests"
          />
        </BottomNavigation>
      </Paper>

    </React.Fragment>
  );
}

export default Finish;
