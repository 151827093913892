import React from "react";
import {Grid, Stack} from '@mui/material';
import styled from "styled-components";


const SizedTypography = styled.a`
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 300;
  `

const LevelLetterSizes = {
  1: 230,
  2: 120,
  3: 80,
  4: 60,
  5: 40,
  6: 30,
  7: 20,
  8: 18,
  9: 15,
  10: 14,
  11: 10,
}

const Letter = ({level, direction, userSettings}: {level: number, direction: number, userSettings: object} ) => {

  let letterSize = LevelLetterSizes[level];
  let adjustedLetterSize = letterSize + userSettings.Acuity.letterSizeScale;

  const directions = {
    0: 'rotate(-180deg)',
    1: 'rotate(-90deg)',
    2: 'rotate(-270deg)',
    3: 'rotate(0deg)',
  }


  return (
    // <Grid item xs={12} flex="1 1 0"  sx={{border: "dashed 1px green"}}>
    <>
      <Grid
        container
        direction="row"
        flexWrap="nowrap"
        justifyContent="space-around"
        alignItems="center"
        alignContent="stretch"
        height="81vh"
        sx={{
          border: "solid 1px yellow",
        }}
        >
        <Grid item key={{letterSize}} xs={12} flex="0 0 0" sx={{letterSize: letterSize}}>
          <svg style={{
            transform: directions[direction],
            border: "solid 1px pink",
            width: adjustedLetterSize + 'px',
          }}><use href="#letter"></use></svg>
        </Grid>

      </Grid>

      <svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>
        <symbol id="letter" viewBox="0 0 66 66">
          <path id="e"strokeOpacity="0" strokeWidth="0" stroke="#FFFFFF" fill="#FFFFFF"
          // d="m65.89411,0l-65.89411,0l0,65.89411l65.89411,0l0,-13.17882l-52.71529,0l0,-13.17882l52.71529,0l0,-13.17883l-52.71529,0l0,-13.17882l52.71529,0l0,-13.17882z"
          d="M65.894 0H0v65.894h65.894V52.715H13.18V39.536h52.715V26.358H13.18v-13.18h52.715V0z"
          />
        </symbol>
      </svg>
    </>

    // </Grid>

  );
}

export default Letter;
