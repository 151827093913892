
import React from "react";
import { Link } from "react-router-dom";
import distance from './images/distance.png';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { Card, Typography, Container, CardMedia, CardContent, CardActions, Stack } from "@mui/material";

import cover_left_eye from './images/cover-left-eye.jpg';

const ChooseDistance = (props) => {
  const root_path = props.root_path;
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="white">Acuity Test</Typography>
        </Grid>
        <Grid item xs={12} md={6} >
          <Card>
            <CardMedia component="img"
                  src={distance}
                  srcSet={`${distance} 1x`}
                  alt="Acuity Test"
                  style={{  resizeMode: 'contain'}}
                  loading="lazy"
                  height="300px"
                />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Place yourself 1 meter from the screen.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

       <Grid item xs={12} md={6} >
         <Card>
          <CardMedia component="img"
                src={cover_left_eye}
                srcSet={`${cover_left_eye} 1x`}
                alt="Acuity Test"
                style={{  resizeMode: 'contain'}}
                loading="lazy"
                height="300px"
              />
          <CardContent>
            <Typography variant="h6">
              Without pressing on the eyelid, cover your right eye with your hand.
            </Typography>

          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
          <Typography variant="h6">
            If you have glasses for distance vision or glasses with progressive lenses, keep them on.
          </Typography>
          </CardContent>
          <CardActions>
            <Stack direction="row" spacing={3} justifyContent="center" alignItems="center" sx={{width:"100%"}}>
              <Button variant="contained" size="small" component={Link} to={`${root_path}/test-right`}>Next</Button>
            </Stack>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
    <div style={{height: "100px"}} />
    </Container>
  );
}

export default ChooseDistance;
