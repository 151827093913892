import React from "react";

import DashboardIcon from '@mui/icons-material/Dashboard';
import SpeedIcon from '@mui/icons-material/Speed';
import SettingsIcon from '@mui/icons-material/Settings';

import Paper from '@mui/material/Paper';

import BottomNavigation from '@mui/material/BottomNavigation';
import { Link } from "react-router-dom";

import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";

// https://stackoverflow.com/questions/54375096/styling-bottomnavigation-in-react-js-material-ui/54375949?noredirect=1#comment122864123_54375949
const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color:'success.main';
  &.Mui-selected {
    color: main;
  }
`);


class MainNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selectedTab,
    }
    this.onSelectTab = props.onSelectTab
  }

  render() {
    return (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4}>
        <BottomNavigation
          showLabels
          value={this.state.value}
          sx={{
            color: 'success.main',
          }}
          onChange={(event, newValue) => {
            this.onSelectTab(newValue);
          }}
        >
          <BottomNavigationAction
            label="Dasbboard"
            icon={<DashboardIcon />}
            component={Link}
            to="/"
          />
          <BottomNavigationAction
            label="Tests"
            icon={<SpeedIcon />}
            component={Link}
            to="/tests"
          />
          <BottomNavigationAction
            label="Appointments"
            icon={<SpeedIcon />}
            component={Link}
            to="/appointments"
          />
          <BottomNavigationAction
            label="Settings"
            icon={<SettingsIcon />}
            component={Link}
            to="/settings"
          />

        </BottomNavigation>
      </Paper>
    )
  }
}

export default MainNavigation;
