const defaultLevelData = {
  1: {
    numberOfTries: 2,
    numberOfSuccessItemsToPass: 1,
    letterSize: 1,
  },
  2: {
    numberOfTries: 2,
    letterSize: 2,
    numberOfSuccessItemsToPass: 2,
  },
  3: {
    numberOfTries: 2,
    letterSize: 3,
    numberOfSuccessItemsToPass: 2,
  },
  4: {
    numberOfTries: 2,
    letterSize: 4,
    numberOfSuccessItemsToPass: 2,
  },
  5: {
    numberOfTries: 2,
    letterSize: 5,
    numberOfSuccessItemsToPass: 2,
  },
  6: {
    numberOfTries: 2,
    letterSize: 6,
    numberOfSuccessItemsToPass: 2,
  },
  7: {
    numberOfTries: 2,
    letterSize: 7,
    numberOfSuccessItemsToPass: 2,
  },
  8: {
    numberOfTries: 2,
    letterSize: 8,
    numberOfSuccessItemsToPass: 2,
  },
  9: {
    numberOfTries: 2,
    letterSize: 9,
    numberOfSuccessItemsToPass: 2,
  },
  10: {
    numberOfTries: 2,
    letterSize: 10,
    numberOfSuccessItemsToPass: 2,
  }
};

const LetterSizesDescription = {
  1: '10%',
  2: '20%',
  3: '30%',
  4: '40%',
  5: '50%',
  6: '60%',
  7: '70%',
  8: '80%',
  9: '90%',
  10: '100%',
}

export {defaultLevelData, LetterSizesDescription};
