import React from "react";
import Test from "./components/Test";
import { Link } from "react-router-dom";
import { Button, Grid, Card, CardMedia, CardContent, CardHeader, Typography, Container} from '@mui/material';

import {LetterSizesDescription} from "./defaults"

import cover_right_eye from './images/cover-right-eye.jpg';

const RightEye = (props) => {
  const eyeName='right';
  const eyeNameHuman='Right Eye';
  const root_path = props.root_path;
  const userSettings = props.userSettings;

  console.log(root_path);
  return (
    <Test root_path={root_path} onFinish={props.onFinish} eye={eyeName} userSettings={userSettings}>
      <Container >
        <Grid container spacing={0} direction="column" alignItems="center" >
          <Grid item xs={12} md={6} sx={{mb: 2}}>
            <p>Your {eyeNameHuman} eye vision is {LetterSizesDescription[props.levelIndex - 1]}</p>
          </Grid>
          <Grid item xs={12} md={6} sx={{mb: 2}}>
            <Card>
              <CardHeader title="Switch Eyes"/>
              <CardMedia component="img"
                    src={cover_right_eye}
                    srcSet={`${cover_right_eye} 1x`}
                    alt="Acuity Test"
                    style={{  resizeMode: 'contain'}}
                    loading="lazy"
                    height="400px"
                  />
              <CardContent>
                <Typography variant="h6">
                  Without pressing on the eyelid, cover your {eyeNameHuman.toLowerCase()} with your hand.
                </Typography>

              </CardContent>
            </Card>
          </Grid>

          <Button variant="contained" component={Link} to={`${root_path}/test-left`}>Continue with left eye</Button>
        </Grid>


      </Container>
    </Test>
  );
}

export default RightEye;
