import {React, useState} from "react";

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import {LetterSizesDescription} from "./defaults"

import ChooseDistance from "./ChooseDistance.js"
import RightEye from "./RightEye.js"
import LeftEye from "./LeftEye.js"
import Finish from "./Finish.js"
import Layout from "Layout/Layout.tsx"



function NoPageFound() {
  return (
    <div>
      <p>No Page Found!</p>
    </div>
  );
}

const Acuity = (props:any) => {
  const { path } = useRouteMatch();

  const [leftEyeAnswers, setLeftEyeAnswers] = useState({});
  const [rightEyeAnswers, setRightEyeAnswers] = useState({});
  const [userSettings, updateSettings] = useState({Acuity: {letterSizeScale: 10}});

  // // https://www.pluralsight.com/guides/fetching-data-updating-state-hooks
  // React.useEffect(function effectFunction() {
  //         async function fetchSettings() {
  //           const response = await fetch('https://eyecare.gudasoft.com/api/acuity/levels');
  //           const json = await response.json();
  //           updateSettings(json.data);
  //       }
  //       fetchSettings();
  // }, []);


  const maxLevel = (answers) => {
    return 2;
  }


  return (
    <Layout selectedTab={props.selectedTab} onSelectTab={props.onSelectTab} >
      <Switch>
        <Route exact path={[`${path}`, `${path}/choose-distance`]}>
          <ChooseDistance root_path={path} />
        </Route>
        <Route path={`${path}/test-right`}>
          <RightEye root_path={path} onFinish={setRightEyeAnswers} levelIndex={maxLevel(rightEyeAnswers)} userSettings={userSettings} />
        </Route>
        <Route path={`${path}/test-left`}>
          <LeftEye root_path={path} onFinish={setLeftEyeAnswers} levelIndex={maxLevel(leftEyeAnswers)} userSettings={userSettings} />
        </Route>
        <Route path={`${path}/finish`}>
          <Finish root_path={path} />
        </Route>
        <Route component={NoPageFound} />
      </Switch>
    </Layout>
  );
}
// onClick={onNextEye}

export default Acuity;
