
import React from "react";

import { Link } from "react-router-dom";

import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";

import {Box, Button, Paper, BottomNavigation, Card, Typography, Container, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

import DashboardIcon from '@mui/icons-material/Dashboard';
import distance from './images/distance.png';

// https://stackoverflow.com/questions/54375096/styling-bottomnavigation-in-react-js-material-ui/54375949?noredirect=1#comment122864123_54375949
const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color:'success.main';
  &.Mui-selected {
    color: main;
  }
`);


const ChooseDistance = (props) => {
  const root_path = props.root_path;
  return (
    <React.Fragment>

      <Box width="100vw" display="flex" flexDirection="column" justify-content="center" align-items="flex-start">
          {/* <Box flex={"1 1"} overflow="auto"> */}
            <picture>
              <img
                  src={distance}
                  srcSet={`${distance} 1x`}
                  alt="Acuity Test"
                  style={{  resizeMode: 'contain', width: '100%', height: '100%' }}
                  loading="lazy"
                />
            </picture>
          {/* </Box> */}
      </Box>

      <Card sx={{mt: 2}}>
        <CardContent>
          <Container >
            <Typography variant="body1" gutterBottom >
              These tests have no diagnostic value. In case of difficulties, only an eye care professional can carry out a complete eye examination to detect any eventual visual problems.
            </Typography>
            <Typography variant="body1" gutterBottom >
              No personal health information is collected or retained as the result of taking these tests.
            </Typography>
          </Container>
        </CardContent>
      </Card>


      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4}>
        <BottomNavigation
          showLabels
          sx={{
            color: 'success.main',
          }}
        >
          <Button variant='contained'
            component={Link}
            to={`${root_path}/cover-left-eye`}>
            I am Ready to Start
          </Button>
          <BottomNavigationAction
            label="Dashboard"
            icon={<DashboardIcon />}
            component={Link}
            to="/tests"
          />
        </BottomNavigation>
      </Paper>
    </React.Fragment>
  );
}

export default ChooseDistance;
