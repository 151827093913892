import React  from "react";


import MainNavigation from "./MainNavigation";
import { Container} from "@mui/material";

const Layout = ({title="Title", selectedTab, onSelectTab, children}) => {

  return (
    <>
      <Container>
        {children}
        {/* <div style={{height: "100px"}} /> */}
      </Container>

      <MainNavigation selectedTab={selectedTab} onSelectTab={onSelectTab}/>
    </>
  )
}

export default Layout;
