
import React from "react";

import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import cover_right_eye from './images/cover-right-eye.jpg';

import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';

import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";

// https://stackoverflow.com/questions/54375096/styling-bottomnavigation-in-react-js-material-ui/54375949?noredirect=1#comment122864123_54375949
const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color:'success.main';
  &.Mui-selected {
    color: main;
  }
`);


const CoverRightEye = (props) => {
  const root_path = props.root_path;

  return (
    <React.Fragment>
      <Box width="100vw" display="flex" flexDirection="column" justify-content="center" align-items="flex-start">
          {/* <Box flex={"1 1"} overflow="auto"> */}
            <picture>
              <img
                  src={cover_right_eye}
                  srcSet={`${cover_right_eye} 1x`}
                  alt="Cover Left Eye"
                  style={{  resizeMode: 'contain', width: '100%', height: '100%' }}
                  loading="lazy"
                />
            </picture>
          {/* </Box> */}
      </Box>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4}>
        <BottomNavigation
          showLabels
          sx={{
            color: 'success.main',
          }}
        >
          <BottomNavigationAction
            label="Back"
            icon={<ArrowBackIcon />}
            component={Link}
            to={`${root_path}`}
          />
          <Button variant='contained'
            component={Link}
            to={`${root_path}/grid-right-eye`}>
            Test Left Eye
          </Button>
          <BottomNavigationAction
            label="Dasbboard"
            icon={<DashboardIcon />}
            component={Link}
            to="/tests"
          />
        </BottomNavigation>
      </Paper>

    </React.Fragment>
  );
}

export default CoverRightEye;
