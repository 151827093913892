import React from "react";

import { Link } from "react-router-dom";
import { Button, Grid, Card, CardContent, CardHeader, Typography, Container} from '@mui/material';

import Test from "./components/Test";
import {LetterSizesDescription} from "./defaults"



const LeftEye = (props) => {
  const eyeName='left';
  const eyeNameHuman='Left Eye';
  const root_path = props.root_path;
  const userSettings = props.userSettings;
  console.log("left eye props", props);
  return (
    <Test root_path={root_path} onFinish={props.onFinish} eye={eyeName} userSettings={userSettings}>
        <Container >
          <Grid container spacing={0} direction="column" alignItems="center" sx={{border: "dotted 1px white"}}>
            <Grid item xs={12} md={6} sx={{mb: 2}}>
              <p>Your {eyeNameHuman} eye vision is {LetterSizesDescription[props.levelIndex - 1]}</p>
            </Grid>

            <Grid item xs={12} md={6} sx={{mb: 2}}>
            <Card>
              <CardHeader title="Finish"/>
              <CardContent>
                <Typography variant="h6">
                  Thank you for taking the test. Click Finish to see your results.
                </Typography>

              </CardContent>
            </Card>
          </Grid>

          </Grid>
        </Container>


      <Button variant="contained" component={Link} to={`${root_path}/finish`}>Finish</Button>
    </Test>
  );
}

export default LeftEye;
