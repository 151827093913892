import React, { useState } from 'react';

import {Grid} from '@mui/material';
import Level from "./Level";

import {defaultLevelData} from "../defaults"

const Test = (props) => {
  const root_path = props.root_path;
  const userSettings = props.userSettings;

  const emptyAnswers = Object.fromEntries(
    Object.entries(defaultLevelData).map(entry => [entry[0], []])
  );

  // const onNextEye = () => {
  //   setAnswers(emptyAnswers);
  //   setLevelIndex(1)
  //   console.log(answers);
  // };

  const [levels, setLevels] = useState(defaultLevelData);
  const [levelIndex, setLevelIndex] = useState(1);
  const [answers, setAnswers] = useState(emptyAnswers);

  const onLevelFinish = (levelIndex, level) => {
      setLevelIndex(levelIndex + 1);
      console.log("level increased", levelIndex);
  }

  const onAnswer = (levelIndex, answer) => {
    const answersCopy = {
        ...answers,
    }

    answersCopy[levelIndex].push(answer);

    console.log("new answers", answersCopy);
    setAnswers(answersCopy);
  }

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      justifyContent="flex-start"
      // alignItems="stretch"
      alignContent="center"
      height="91vh"
      sx={{border: "1px solid gray"}}
    >
      { levelIndex <= Object.entries(defaultLevelData).length &&
        <Level
          levelIndex={levelIndex}
          level={levels[levelIndex]}
          onLevelFinish={onLevelFinish}
          onAnswer={onAnswer}
          userSettings={userSettings}
        />
      }

      { levelIndex > Object.entries(defaultLevelData).length && props.children}

      {/* <Grid item xs={12} flex="0 1 50px" sx={{fontSize: "16px"}}>
        <p>{JSON.stringify(answers)}</p>
      </Grid> */}
    </Grid>

  );
}

export default Test;
