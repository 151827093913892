import React from "react";
import Container from '@mui/material/Container';
import Layout from 'Layout/Layout.tsx'

import { BarChart, Bar} from 'recharts';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

// https://recharts.org/en-US/examples/TinyBarChart
const Dashboard = (props) => {
  return (
    <Layout selectedTab={props.selectedTab} onSelectTab={props.onSelectTab} >
      <Container>
        <Grid container wrap="nowrap" spacing={1} rowSpacing={1}  direction="column"   justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            <h2>Before you start</h2>
          </Grid>

          <Grid item xs={12}>
              {/* <Box sx={{ }}> */}
                {/* <Paper> */}
                  <Container>
                    <Typography variant="body1" color="text.info" gutterBottom>
                      This app helps you to monitor your eye health over time. It won't replace the need to visit a doctor but will help you to note trends in some aspect of your eye health so that you can decide on the proper frequency of visits to the doctor's office.
                    </Typography>
                    <Typography variant="body1" color="text.info" gutterBottom>
                      Currently two tests are provided in this app: Amsler and Acuity tests.
                    </Typography>
                    <Typography variant="body1" color="text.info" gutterBottom>
                      Acuity test can be used to monitor how well you can read letters of different sizes from a specific distance.
                    </Typography>
                    <Typography variant="body1" color="text.info" gutterBottom>
                      The Amsler test is usually used to monitor for Glaucoma. Glaucoma refers to an eye condition that results in irregularity in vision. It progresses without apparent symptoms and can lead to complete loss of eyesight if not treated on time.
                    </Typography>

                    {/* <BarChart width={250} height={140} data={data}>
                        <Bar dataKey="uv" fill="#8884d8" />
                    </BarChart>
                    <Typography variant="caption" color="text.secondary">
                      Days in a row
                    </Typography> */}
                  </Container>
                {/* </Paper> */}
              {/* </Box> */}
          </Grid>

        </Grid>
      </Container>
    </Layout>
  );
}

export default Dashboard;
